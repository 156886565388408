<script setup>
import { defineProps, defineEmits } from 'vue'
const props = defineProps(['data', 'main'])

const emit= defineEmits(["selectCategory"])
 
function tiklandi(cat, name) {
    emit("selectCategory", cat, name);
}


let ulClass = 'list-unstyled mb-0'
if(props.main != true){
    ulClass = "ps-4"
}

</script>

<template>
    <ul :class="ulClass" v-for="subs, cat in props.data" :key="cat">
        <li>
            <span href="#" class="d-flex py-1" v-if="Object.keys(subs.subs).length < 1" >
                <div class="flex-grow-1">
                    <h5 class="fs-13 mb-0" :data-cat-id="cat" @click="tiklandi(cat, subs.name)">
                        {{ subs.name }}
                    </h5>
                </div>
            </span>
            <div v-else>
                <span 
                    class="d-flex py-1" 
                    data-bs-toggle="collapse" 
                    :href="'#c-' + cat.slugify()" 
                    role="button"  aria-expanded="true" :aria-controls="'c-' + cat.slugify()"
                    >
                        <div class="flex-grow-1">
                            <h5 class="fs-13 mb-0" :data-cat-id="cat" @click="tiklandi(cat, subs.name)">{{ subs.name }}</h5>
                        </div>
                        <div class="flex-shrink-0 ms-2">
                            <span class="badge bg-light text-muted">{{
                                Object.keys(subs.subs).length
                            }}</span>
                        </div>
                </span>
                <div class="collapse" :id="'c-' + cat.slugify()">
                    <categories :data="subs.subs" @selectCategory="tiklandi"></categories>
                </div>
            </div>
            <!-- <categories  :data="subs.subs"></categories> -->
        </li>
    </ul>
  <!-- <categories :data="props.data.subs"></categories> -->
</template>